import { Controller } from "@hotwired/stimulus"
// si falla el llamado de algun modal descomentar una de las dos siguientes lineas:
//import { Modal } from "bootstrap";
//import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element);
    //cuando el modal se oculta se vacia el modal-body para evitar que persista algun código y se duplique al abrir otro modal
    this.element.addEventListener('hidden.bs.modal', (event) => {
      $(".vaciar_conten").html("");
    })
    //fin de vaciado de modal
  }

  open() {
    if (!this.modal.isOpened) {
      this.modal.show()
      $('.focus_in').focus();
    }
  }

  close(event) {
    if (event.detail.success) {
      this.modal.hide()
    }
  }
}
